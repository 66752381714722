<style scoped>
    #article-container :deep(.inputeo) {
        min-width: 400px;
        max-width: 400px;
    }

    #article-statistic-subtitle {
        text-align: center;
        margin: 20px;
        font-style: italic;
    }

    #article-statistic-table {
        margin: 30px 20px;
    }

    #article-statistic-table thead th {
        min-width: 200px;
    }

    #article-statistic-table tbody td {
        text-align: center;
    }
</style>

<template>
    <ButtonTopbar buttonText="Statistiques" @onTopbarButtonClicked="getStatisticsModal" :disabled="disableButtonStatistics">
        <template v-slot:iconBefore>
            <font-awesome-icon :icon="['fas', 'chart-bar']" />
        </template>
    </ButtonTopbar>
    <Aggrid
        apiRoute="stockMovement/list"
        :columnDefs="columnDefs" 
        :context="context"
        :frameworkComponents="frameworkComponents"
        :cellRendererFunctions="cellRendererFunctions"
        :valueFormatterFunctions="valueFormatterFunctions"
        aggridHeightSubstract="100px"
    >
        <template v-slot:filter>
            <div id="article-container">
                <BaseSelect
                    v-model="articleEstablishmentFilter"
                    label="Etablissement"
                    :defaultOptions="establishments"
                    name="searchArticleEstablishment"
                    :display-tag="true"
                    fieldLabel="label"
                    fieldValue="id"
                />
            </div>
            <div>
                <BaseSelect 
                    v-model="articleSearch"
                    label="Article"
                    @onChange="onFiltersChange($event)"
                    fieldValue="id"
                    fieldLabel="label"
                    :searchable="true"
                    :multiple="true"
                    api="article/search"
                    :apiParams="{
                        establishment: articleEstablishmentFilter?.value
                    }"
                    oneItemSelectedText="article sélectionné"
                    multipleItemsSelectedText="articles sélectionnés"
                />
            </div>
            <div>
                <BaseDatepicker
                    v-model="startedDateSearch"
                    label="Date de début"
                    @onChange="onFiltersChange($event)"
                />
            </div>
            <div>
                <BaseDatepicker
                    v-model="endedDateSearch"
                    label="Date de fin"
                    @onChange="onFiltersChange($event)"
                />
            </div>
        </template>
    </Aggrid>
    <Modal v-show="displayStatisticsModal" :hideIcon="true">
        <template v-slot:modalTitle>
            <div>Statistiques</div>
        </template>
        <template v-slot:modalContent>
            <div id="article-statistic-subtitle">Du {{ startedDateSearch }} au {{ endedDateSearch }}</div>
            <table id="article-statistic-table">
                <thead>
                    <tr>
                        <th>Article</th>
                        <th>Entrant</th>
                        <th>Sortant</th>
                        <th>Différentiel</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="stockMovementsStatistic in stockMovementsStatistics" :key="stockMovementsStatistic">
                        <td>{{ stockMovementsStatistic.label }}</td>
                        <td>{{ stockMovementsStatistic.periodIn }}</td>
                        <td>{{ stockMovementsStatistic.periodOut }}</td>
                        <td>{{ stockMovementsStatistic.periodDifference }}</td>
                    </tr>
                </tbody>
            </table>
        </template>
        <template v-slot:modalButtons>
            <BaseButton class="white-button" @click="closeModal" buttonText="Fermer">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'times']" />
                </template>
            </BaseButton>
            <BaseButton class="blue-button" @click="exportStats" buttonText="Exporter" :disabled="stockMovementsStatistics.length === 0">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'file-invoice']"/>
                </template>
            </BaseButton>
        </template>
    </Modal>
    <Dialog ref="dialog" />
</template>

<script>
    import Aggrid from '../../components/Aggrid/Aggrid';
    import ListStockMovementsActionButtonsRenderer from './ListStockMovementsActionButtonsRenderer.vue';
    import ListStockMovementsDetailRenderer from './ListStockMovementsDetailRenderer.vue';
    import BaseSelect from '../../components/Base/BaseSelect.vue';
    import BaseButton from '../../components/Base/BaseButton.vue';
    import BaseDatepicker from '../../components/Base/BaseDatepicker.vue';
    import Modal from '../../components/App/Modal.vue';
    import Dialog from '../../components/App/Dialog.vue';
    import ButtonTopbar from '../../components/Topbar/ButtonTopbar.vue';
    import axios from 'axios';
    import {parse, isSameDay, isAfter, format} from 'date-fns';
    import { formatPrice } from '../../utils/formatter'

    export default {
        name: 'StockMovements',
        components: {
            Aggrid,
            ListStockMovementsActionButtonsRenderer,
            ListStockMovementsDetailRenderer,
            BaseSelect,
            BaseButton,
            BaseDatepicker,
            Modal,
            Dialog,
            ButtonTopbar
        },
        data() {
            let that = this;

            return {
                columnDefs: [
                    { field: 'date', headerName : `Date`, flex: 1, lockPosition: true, filter: 'agDateColumnFilter' },
                    { field: 'affairNumber', headerName : `Affaire`, flex: 1, lockPosition: true, customFilter: 'affair.affairNumber' },
                    { field: 'article.label', headerName : `Article`, flex: 2, lockPosition: true },
                    { field: 'buyPrice', headerName : `Prix d'achat`, flex: 1, lockPosition: true, valueFormatterFunctionName: 'BuyPriceValueFormatterFunction', filter: 'agNumberColumnFilter' },
                    {
                        headerName : `Genre`, 
                        flex: 1, lockPosition: true,
                        cellRendererFunctionName: 'GenderRendererFunction', 
                        suppressMenu: true},
                    { field: 'comment', headerName : `Commentaire`, flex: 2, lockPosition: true, wrapText: true },
                    { field: 'movement', headerName : `Mouvement`, flex: 1, lockPosition: true, filter: 'agNumberColumnFilter' },
                    { flex: 1, lockPosition: true, cellRenderer: 'ListStockMovementsDetailRenderer'},
                    {
                        flex: 1,
                        lockPosition: true,
                        cellRendererFunctionName: 'DeletedDateRendererFunction',
                        suppressMenu: true},
                    { flex: 1, lockPosition: true, cellRenderer: 'ListStockMovementsActionButtonsRenderer', suppressMenu: true}
                ],
                frameworkComponents: {
                    ListStockMovementsActionButtonsRenderer: ListStockMovementsActionButtonsRenderer,
                    ListStockMovementsDetailRenderer: ListStockMovementsDetailRenderer
                },
                cellRendererFunctions: new Map([
                    [
                        'GenderRendererFunction',
                        function(param) {
                            return param?.data?.movement > 0 ? that.stockMovementKindIn.find(element => element.value === param?.data?.kind)?.label : that.stockMovementKindOut.find(element => element.value === param?.data?.kind)?.label;
                        }
                    ],[
                        'DeletedDateRendererFunction',
                        function(param) {
                            return param?.data?.deleted ? `Annulé le ${param?.data?.deletedAt}` : '';
                        }
                    ]
                ]),
                valueFormatterFunctions: new Map([
                    [
                        'BuyPriceValueFormatterFunction',
                        function(params){
                            return params?.data?.buyPrice != null ? formatPrice(params?.data?.buyPrice) : '';
                        }
                    ]
                ]),
                context: null,
                articleSearch: null,
                articleEstablishmentFilter: null,
                startedDateSearch: null,
                endedDateSearch: null,
                disableButtonStatistics: true,
                stockMovementsStatistics: [],
                displayStatisticsModal: false,
                stockMovementKindIn: [
                    { label: 'Affaire', value: 'affair' },
                    { label: 'Inventaire', value: 'inventory' }
                ],
                stockMovementKindOut: [
                    { label: 'Affaire', value: 'affair' },
                    { label: 'Inventaire', value: 'inventory' },
                    { label: 'Casse', value: 'broken' }
                ],
                establishments: [
                    { label: 'EBC', value: 'ebc' },
                    { label: 'SMG', value: 'smg' }
                ]
            }
        },
        beforeMount() {
            this.context = {
                componentParent: this
            };
        },
        methods: {
            deleteOrEnableStockMovement(stockMovement) {
                this.$refs.dialog.show({
                    type: 'confirm',
                    title: 'Confirmation',
                    message: `Êtes-vous sûr de vouloir ${stockMovement.deleted ? 'réintégrer' : 'annuler'} ce mouvement de stock ?`
                }).then(() => {
                    axios
                    .put('stockMovement/changeDeleted/' + stockMovement.id, {
                        deleted: !stockMovement.deleted
                    }, { 
                        toastSuccessMessage: `Modification effectuée`, 
                        toastError: false, 
                        showPreloader: true 
                    })
                    .then(() => {
                        this.emitter.emit('ag-grid-reload')
                    })
                    .catch((error) => {
                        if (error?.response?.status === 409) {
                            this.$refs.dialog.show({
                                type: 'confirm',
                                title: 'Confirmation',
                                message: `Si vous confirmez cette action, le stock de cet article passera en négatif. Êtes-vous sûr ?`
                            }).then(() => {
                                axios
                                .put('stockMovement/changeDeleted/' + stockMovement.id, {
                                    deleted: !stockMovement.deleted,
                                    force: true
                                }, { 
                                    toastSuccessMessage: `Modification effectuée`, 
                                    toastError: true, 
                                    showPreloader: true 
                                })
                                .then(() => {
                                    this.emitter.emit('ag-grid-reload')
                                });
                            })
                            .catch(() => {});
                        } else {
                            createToast(error.response.data, {
                                position: 'bottom-right',
                                type: 'danger',
                                hideProgressBar: true,
                                showIcon: true,
                                transition: 'slide'
                            });
                        }
                    });
                })
                .catch(() => {});
            },
            onFiltersChange() {
                this.disableButtonStatistics = (this.startedDateSearch == null || this.startedDateSearch.trim().length === 0) || (this.endedDateSearch == null || this.endedDateSearch.trim().length === 0);
            },
            getStatisticsModal() {
                let startedDateSearch = parse(this.startedDateSearch, 'dd/MM/yyyy', new Date());
                let endedDateSearch = parse(this.endedDateSearch, 'dd/MM/yyyy', new Date());

                if (isAfter(endedDateSearch, startedDateSearch) || isSameDay(endedDateSearch, startedDateSearch)) {
                    axios
                    .get(`article/statistic?${this.articleSearch ? 'articles=' + this.articleSearch.map(article => article.value).join(',') : ''}&startedDate=${this.startedDateSearch}&endedDate=${this.endedDateSearch}`, {
                        toastError: true, 
                        showPreloader: true 
                    })
                    .then((result) => {
                        this.displayStatisticsModal = true;
                        this.stockMovementsStatistics = result.data;
                    });
                } else {
                    this.$refs.dialog.show({
                        type: 'alert',
                        title: 'Attention',
                        message: `La date de début ne peut pas être après la date de fin`,
                        okButton: 'OK'
                    });
                }
            },
            closeModal() {
                this.displayStatisticsModal = false;
            },
            exportStats() {
                let rows = [];
                rows.push(['Article', 'Entrant', 'Sortant', 'Différentiel']);
                for (let data of this.stockMovementsStatistics) {
                    rows.push([data.label, data.periodIn, data.periodOut, data.periodDifference]);
                }
                let csvContent = rows.map(e => e.join(";")).join("\n");

                this.exportCSV(csvContent, 'export_mouvements_stock_stats_');
            }
        }
    }
</script>